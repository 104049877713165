<template>
  <div class="grid-container">
    <div class="grid-item-1">
      <img class="logo_right" alt="Vue logo" src="../assets/cnhu_img.jpg"/>
    </div>
    <div class="grid-item-2">
      <div class="grid-item2-1">
        <div class="title">
          <div class="title1">
            <p class="cnhu">CNHU - Prise de rdv en ligne</p>
            <p class="enr_title">Enregistrement des médecins</p>
          </div>
          <div class="title2">
            <img
                class="cnhu-logo"
                alt="Vue logo"
                src="../assets/cnhu-logo.png"
            />
          </div>
        </div>

        <!-- <img  alt="Vue logo" src="../assets/cnhu-logo.png" /> -->
        <b-card class="formulaire">
          <div>
            <b-form @submit="onSubmit" @reset="onReset" v-if="show">
              <b-form-group
                  label="Catégories de services"
                  label-for="categorie-service"
                  label-cols-sm="4"
                  label-align-sm="initial"
                  class="categorie"
              >

                <b-form-select class="form-select" id="categorie-service" @input="changeCategoriService" list="categorie-service"
                               v-model="form.categorie"
                               required>
                  <option
                      v-for="serviceCategorie in serviceCategories"
                      :key="serviceCategorie"
                  >
                    {{ serviceCategorie }}
                  </option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                  label="Spécialités"
                  label-for="specialite"
                  label-cols-sm="4"
                  label-align-sm="right"
                  class="specialite"
              >
                <b-form-select id="specialite" list="specialite"
                               v-model="form.specialite"
                               required
                               @input="changeSpecialiteService">
                  <option v-for="specialite in specialites" :key="specialite">
                    {{ specialite }}
                  </option>
                </b-form-select>
              </b-form-group>

              <b-form-group
                  label="Spécialistes"
                  label-for="medecin"
                  label-cols-sm="4"
                  label-align-sm="right"
                  class="specialiste"
              >

                <b-form-select id="medecin" list="medecin"
                               v-model="form.medecin"
                               required>
                  <option v-for="medecin in lstMedecins" :key="medecin" v-on:click="changeSpecialiste(medecin.id)">
<!--                    {{medecin}}-->
                   {{ medecin.titre }} {{ medecin.nom }} {{ medecin.prenom }}
                  </option>

                </b-form-select>
              </b-form-group>
              <div v-if="form.medecin">
                <ul>
                  <li v-for="(dispoKey, dispoValue) in lstDispoMedecin" :key="(dispoKey, dispoValue)">
                     {{dispoValue}} ({{dispoKey}})
                  </li>
                </ul>
              </div>
              <b-row>
                <b-col lg="4" class="pb-2"><b-button @click="retirer_medecin()" variant="secondary">
                  Retirer le médecin
                </b-button></b-col>
              </b-row>

              <b-card
                  class="consultation_pro"
                  header="Ajout ou mise à jour d'un créneau de consultation du médecin / Suppression d'un médecin"
              > {{form.medecin}}
                <div class="programmation">
                  <div class="day">
                    <b-form-group
                        id="fieldset-1"
                        label="Jour consultation"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          list="day"
                          v-model="form.day"
                          @input="daySelect"
                      ></b-form-input>

                      <datalist id="day">
                        <option v-for="day in days" :key="day">
                          {{ day }}
                        </option>
                      </datalist>
                    </b-form-group>
                  </div>
                  <div class="starthour">
                    <b-form-group
                        id="fieldset-1"
                        label="Heure début"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          list="hourStart"
                          v-model="form.hourStart"
                          @input="hourselectStart"
                      ></b-form-input>

                      <datalist id="hourStart">
                        <option
                            v-for="hourStart in hourStarts"
                            :key="hourStart"
                        >
                          {{ hourStart }}
                        </option>
                      </datalist>
                    </b-form-group>
                  </div>
                  <div class="endhour">
                    <b-form-group
                        id="fieldset-1"
                        label="Heure fin"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          list="hourEnd"
                          v-model="form.hourEnd"
                          @input="hourselectEnd"
                      ></b-form-input>

                      <datalist id="hourEnd">
                        <option v-for="hourEnd in hourEnds" :key="hourEnd">
                          {{ hourEnd }}
                        </option>
                      </datalist>
                    </b-form-group>
                  </div>
                </div>
                <div>
                  <ul>
                    <li v-for="(dispoKey, dispoValue) in form.hhProgMedecinByDay" :key="(dispoKey, dispoValue)">
                      {{dispoValue}} ({{dispoKey}})
                    </li>
                  </ul>
                </div>
                <b-row>
                  <b-col lg="4" class="pb-2">
                    <b-button @click="ajouter_programmation()" variant="secondary">
                      Ajouter un créneau
                    </b-button>
                  </b-col>
                  <b-col lg="4" class="pb-2">
                    <b-button class="enregistrer" type="submit" variant="outline-primary">
                      Enregistrer
                    </b-button>
                  </b-col>
                </b-row>
              </b-card>

              <b-card
                  class="consultation_pro; color-header"
                  header="Ajout d'un nouveau medecin"
              >
                <div class="programmation">
                  <div class="titre">
                    <b-form-group
                        id="fieldset-1"
                        label="Titre"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          list="titre"
                          v-model="form.titre"
                      ></b-form-input>

                      <datalist id="titre">
                        <option v-for="titre in titres" :key="titre">
                          {{ titre }}
                        </option>
                      </datalist>
                    </b-form-group>
                  </div>
                  <div class="starthour">
                    <b-form-group
                        id="fieldset-1"
                        label="Nom"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          v-model="form.nom"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="endhour">
                    <b-form-group
                        id="fieldset-1"
                        label="Prénom"
                        label-for="input-1"
                        valid-feedback="Thank you!"
                        :invalid-feedback="invalidFeedback"
                        :state="state"
                        class="label"
                    >
                      <b-form-input
                          v-model="form.prenom"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>
                <b-button @click="ajouter_medecin()" variant="secondary">
                  Ajouter
                </b-button
                >
              </b-card>
            </b-form>
          </div>
        </b-card>
      </div>

      <div class="grid-item2-2">
        <p
            style="
            color: white;
            text-align: center;
            margin-top: 10px;
            font-size: 15px;
          "
        >
          © 2022 ASSI - DPD
        </p>
      </div>
    </div>
  </div>
</template>

<script>
var _ = require("lodash");
const axios = require("axios").default;
const url = 'https://api.ecnhu.service-public.bj';
//const url = 'http://10.209.0.29:8090';
//const url = 'http://localhost';

export default {
  data() {
    return {
      loading: true,
      form: {
        name: "",
        categorie: "",
        specialite: "",
        hourEnd: "",
        medecin: "",
        hourStart: "",
        day: "",
        titre: "",
        nom: "",
        prenom: "",
        checked: [],
        hhProgMedecinByDay: {},// horaire de disponibilité pour 1 medecin, 1 catégorie, 1 spécialité et 1 jour sélectionné
      },
      // data from DBB
      lstDispoMedecin: [],
      lstMedecins: [],
      medecinId: "",
      selectedStart: "",
      // other
      show: true,
      serviceCategories: [
        //{ text: "Sélectionnez une catégorie", value: null },
        "Consultation de spécialité chirurgicales",
        "Consultation de spécialité médicales",
        "Autres types de consultation",
      ],
      specialites: [
        // { text: "Sélectionnez une spécialité", value: null },
        "Traumatologie",
        "Neurochirurgie",
        "Urologie",
        "Chirurgie plastique",
        "Chirurgie Viscérale",
        "Chirurgie Pédiatrique",
        "Chirurgie cardio-thoracique",
        "Consultation de spécialité médicales",
        "Rhumatologie",
        "Médecine Interne",
        "Nephrologie",
        "Endocrinologie",
        "Neurologie",
        "ORL-CCF",
        "Medecine physique et Réadaptation",
        "Cardiologie",
        "Pédiatrie",
        "Dermatologie",
        "Psychiatre",
        "Stomatologie",
        "Ophtalmologie",
        "Maladie du sang",
        "Gynecologie-obstetrique(CUGO)",
        "Hepato Gastro-Entérologie",
        "Nutrition",
      ],
      specialites_chirurgicales: [
        // { text: "Sélectionnez une spécialité", value: null },
        "Traumatologie",
        "Neurochirurgie",
        "Urologie",
        "Chirurgie plastique",
        "Chirurgie Viscérale",
        "Chirurgie Pédiatrique",
        "Chirurgie cardio-thoracique",
      ],
      specialites_medicals: [
        // { text: "Sélectionnez une spécialité", value: null },
        "Rhumatologie",
        "Médecine Interne",
        "Nephrologie",
        "Endocrinologie",
      ],
      specialites_autres: [
        //  { text: "Sélectionnez une spécialité", value: null },
        "Neurologie",
        "ORL-CCF",
        "Medecine physique et Réadaptation",
        "Cardiologie",
        "Pédiatrie",
        "Dermatologie",
        "Psychiatre",
        "Stomatologie",
        "Ophtalmologie",
        "Maladie du sang",
        "Gynecologie-obstetrique(CUGO)",
        "Hepato Gastro-Entérologie",
        "Nutrition",
      ],
      /*  lstMedecins: [
        //  { text: "Sélectionnez un médecin", value: null },
          "Prof ADEGBIDI Hugues",
          "Dr DEGBOE Bérénice",
          "Prof. ATADOKPEDE Félix",
          "Dr AKPADJAN Fabrice",
          "Prof HANS MOEVI Aristote",
          "Dr CHIGBLO Pascal",
          "Dr GOUKODADJA Oswald",
          "Dr PADONOU Franck",
          "Chirurgie cardio-thoracique",
          "Dr GANDJI Wilfried",
          "Dr AGBESSI Odry",
          "Dr YEVI Magloire",
          "Pr AVAKOUDJO Josué",
          "Dr HODONOU Fred",
          "Dr AGOUNKPE Michel",
          "Prof. FIOGBE Michel",
          "Dr METCHIHOUNGBE Serge",
          "Dr SOGBO Oscar",
          "Pr MEHINTO Delphin",
          "Pr GNONLONFOUN Dieu donné",
          "Dr AGBOTON Bruno",
          "Dr ZOSSOUNGBO Giovanna",
          "Pr VIGAN Jacques",
          "Dr Sandrine LOKO",
          "Dr AYADJI Eric",
          "Dr BIO-TCHANE",
          "Dr BANCOLE POGNON Sylvie",
          "Dr ADJALLA Jean Guy",
          "Pr KEKEKOU Annelie",
          "Dr DJEGBO",
          "MCA AZON KOUANOU Angèle",
          "Dr ASSOGBA",
          "MCA. AGBODANDE Anthelme",
          "Dr MISSIHO",
          "Pr. EZIN HOUNGBE Josiane",
          "Dr CLACO",
          "Dr MAMANE",
          "Mme ABDOULAYE Naïmatou",
          "Dr ASSOGBA ZOMALHETO Michée",
          "Dr KOONOU Nadège",
          "Prof. LALYA Francis",
          "Dr BIO NIGAN Raimath",
          "Dr BAGNAN TOSSA Léhila",
          "Dr TOHODJEDE Yévèdo",
          "Dr SEIBOU Hayathou",
          "Dr LANKPEKO AFFOLABI Clémence",
          "Dr BIO NIGAN Raimath",
          "Dr Raïmi KPOSSOU",
          "Pr Jean SEHONOU",
          "Dr Martin SOKPON",
          "Dr Rodolphe VIGNON",
          "Pr ZOMALHETO Zavier",
          "Dr DOSSOU YOVO Hilaire",
          "Dr SONOU Arnaud",
          "Dr GANDJI Wilfried",
          "Pr CODJO Léopold",
          "Dr FADONOUGBO Xavier",
          "Dr AGBALIKA",
          "Dr BORI BATA",
          "DR HOUNKPONOU Murielle",
          "Prof KPADONOU Toussaint",
          "Pr ALAGNIDE Etienne",
          "Prof KPADONOU",
          "Dr NIAMA NATTA",
          "Dr ABOUKI",
          "Pr ALAMOU",
          "Dr VIDEGLA",
          "Dr DJOSSOU",
          "Dr. Ligali ALLI",
          "Dr AHLONSOU Marcel",
          "Pr C. TSHABU AGUEMON Christiane",
          "Dr Ligali ALLI",
          "Pr DENAKPO Justin",
          "Dr. Adama TAÏROU",
        ],*/
      /*options: [
        { value: "A", text: "Option A (from options prop)" },
        { value: "B", text: "Option B (from options prop)" },
      ],*/
      hourStarts: [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ],
      titres: ["Dr", "Prof", "Pr", "Mr", "Mme"],
      hourEnds: [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
      ],
      days: ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    };
  },
  async created() {
    //this.getMedecins();
  },
  /* watch:{
     "form.medecin"(value){
       console.log("watch" + value)
     }
   },*/
  methods: {
    onSubmit() {
      console.log(" this.form.hhProgMedecinByDay", this.form.hhProgMedecinByDay);
       /*Object.keys(this.form.hhProgMedecinByDay).forEach(key => {
        console.log(key, this.form.hhProgMedecinByDay[key]);
      })
      console.log(JSON.stringify(this.form.hhProgMedecinByDay))*/
      this.saveMedecin(Object.entries(this.form.hhProgMedecinByDay));

      // reset
      this.hh_disponibilite = '';
      this.form.hhProgMedecinByDay = {}
      this.lstDispoMedecin = [];
      this.getDispoMedecinById(this.medecinId)
    },
    ajouter_medecin() {
      this.addMedecin();
      this.form.titre = "";
      this.form.nom = "";
      this.form.prenom = "";
    },
    retirer_medecin() {
      this.delMedecin(this.medecinId);
    },
    changeCategoriService(event) {
      if (event == "Consultation de spécialité chirurgicales")
        this.specialites = this.specialites_chirurgicales;
      if (event == "Consultation de spécialité médicales")
        this.specialites = this.specialites_medicals;
      if (event == "Autres types de consultation")
        this.specialites = this.specialites_autres;
      // resetSpecialistes
      this.lstDispoMedecin = [];
      this.lstMedecins = [];
    },
    changeSpecialiteService(event) {
      console.log("event (changeSpecialiteService)=" + event)
      this.lstDispoMedecin = [];
      this.lstMedecins = [];
      this.form.specialite = event;
      this.getMedecins()
    },
    changeSpecialiste(event) {
      console.log("event (changeSpecialiste)= " + event)
      this.medecinId = event;
      this.lstDispoMedecin = [];
      this.getDispoMedecinById(this.medecinId)
    },
    daySelect(event) {
      console.log("event (daySelect)= " + event)
    },
    resetForm() {
      this.form.name = "";
      this.form.categorie = "";
      this.form.specialite = "";
      this.form.medecin = "";
      this.form.hourEnd = "";
      this.form.hourStart = "";
      this.form.checked = [];
      this.form.titre = "";
      this.form.nom = "";
      this.form.prenom = "";
      this.form.day= "";
      this.form.hhProgMedecinByDay ={}
    },
    ajouter_programmation() {
      //console.log("this.hhProgMedecinByDay", this.form.hhProgMedecinByDay);
      (this.form.hhProgMedecinByDay[this.form.day] = this.hh_disponibilite);
      (this.form.day = "");
      (this.form.hourEnd = "");
      this.form.hourStart = "";
    },
    hourselectStart(event) {
      if (this.hourStarts.filter((x) => x === event).length > 0) {
        this.hh_disponibilite = event + "-";
        this.selectedStart = this.hh_disponibilite;
        let indice = this.hourStarts.findIndex((elt) => elt === event);
        indice = indice + 1;
        //console.log("e", this.hourStarts.slice(0, indice));
        this.hourEnds = _.difference(
            this.hourStarts,
            this.hourStarts.slice(0, indice)
        );
      }
      //console.log("e", this.hourselectEnd);
    },
    hourselectEnd(event) {
      if (this.hourEnds.filter((x) => x === event).length > 0) {
        this.hh_disponibilite = this.selectedStart + event;
      }
    },
    onReset(event) {
      event.preventDefault();
      this.resetForm();
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    saveMedecin(data) {
      axios
          .put(url + '/medecin', data, {
            params: {
              id: this.medecinId
            }
          })
          .then(function (response) {
            alert("Le médecin a été mis à jour!");
            return response.status;
          })
          .catch(function (error) {
            alert("Une erreur s'est produite lors de la mise à jour du médecin " + this.form.nom + " " + this.form.prenom);
            return error;
          });
    },
    addMedecin() {
      axios
          .post(url + '/medecin', null, {
            params: {
              titre: this.form.titre,
              nom: this.form.nom,
              prenom: this.form.prenom,
              categorie: this.form.categorie,
              specialite: this.form.specialite
            }
          })
          .then(function (response) {
            alert("Le médecin a été ajouté!");
            return response.status;
          })
          .catch(function (error) {
            alert("Une erreur s'est produite lors de l'enregistrement du médecin " + this.form.nom + " " + this.form.prenom);
            return error;
          });
    },
    delMedecin(data) {
      axios
          .delete(url + '/medecin/' + data)
          .then(function (response) {
            alert("Le médecin a été supprimé!");
            return response.status;
          })
          .catch(function (error) {
            alert("Une erreur s'est produite lors de la suppression du médecin " + this.form.nom + " " + this.form.prenom);
            return error;
          });
    },
    getDispoMedecinById(data) {
      axios
          .get(url + '/medecin/' + data)
          .then((response) => {
            let result = response.data
            console.log("response.data (getDispoMedecinById) = ", response.data)
            console.log("response.data.dispoBySpeAndCatAsMap = ", result["dispoBySpeAndCatAsMap"])
            let obj = result["dispoBySpeAndCatAsMap"]
            this.lstDispoMedecin = obj
           /* Object.keys(obj).forEach(key => {
              console.log(key, obj[key]);
              //this.lstDispoMedecin.push(key + " " + obj[key]);
              //this.lstDispoMedecin[key] = obj[key]);
            });*/
            //this.lstDispoMedecin = result["dispoBySpecialiteAndCategorie"]
            //this.form.medecin = result["titre"] + " " + result["nom"] + " " + result["prenom"]
            //alert("Le médecin a été trouvé!");
            return response.status;
          });
    },
    getMedecins: function () {
      this.loading = true
      axios.get(url + '/medecins', {
        params: {
          categorie: this.form.categorie,
          specialite: this.form.specialite
        }
      })
          .then((response) => {
            this.loading = false
            let result = response.data
            if (Object.keys(result).length > 1) {
              console.log("response.data (getMedecins) = ", response.data)
              this.lstMedecins = response.data
              console.log("lstMedecins = ", this.lstMedecins)
            } else {
              let id = Object.keys(result)[0]
              console.log("id = ", id)
              console.log("result = ", result[id])
              this.lstMedecins.push(result[id])
              //this.lstMedecins[id] = result[id]
              console.log("lstMedecins = ", this.lstMedecins)
            }
          });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.consultation_pro {
  margin-top: 40px;
}
.color-header {
  background: lightsteelblue;
}
.enregistrer {
  margin-top: 20px;
}

.label {
  text-align: initial;
}

.programmation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "day starthour endhour";
  gap: 50px;
}

.add {
  margin-top: 25px;
  grid-area: add;
  margin-left: -45px;
}

.day {
  grid-area: day;
}

.starthour {
  grid-area: starthour;
}

.endhour {
  grid-area: endhour;
}

.cnhu-logo {
  width: 100%;
  margin-top: -10px;
}

.card-class {
  margin: 0 auto;
  width: 90%;
}

.grid-container {
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "grid-item-1 grid-item-2";
}

.title {
  display: grid;
  grid-template-columns: 5fr 1fr;
}

.formulaire {
  width: 90%;
  margin: 0 auto;
}

.categorie {
  text-align: initial;
}

.specialite {
  text-align: initial;
}

.specialiste {
  text-align: initial;
}

.logo_right {
  width: 100%;
  height: 100%;
}

.cnhu {
  margin-top: 20px;
  text-transform: uppercase;
  font-size: 30px;
  color: #0a3764;
  font-family: "Montserrat", sans-serif;
}

.enr_title {
  margin-top: -10px;
  font-size: 24px;
  color: #0a3764;
  font-family: "Montserrat", sans-serif;
}

.grid-item-1 {
  grid-area: grid-item-1;
}

.grid-item-2 {
  grid-area: grid-item-2;
  grid-template-rows: 10fr 40px;
  display: grid;
}

.grid-item2-2 {
  background-color: black;
}

@media screen and (max-width: 850px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-areas: "grid-item-2";
  }

  .programmation {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "day"
      "starthour"
      "endhour";
    gap: 10px;
  }

  .grid-item-1 {
    display: none;
  }

  .grid-item-2 {
    width: 100%;
  }

  .cnhu {
    text-transform: uppercase;
    font-size: 20px;
  }

  .enr_title {
    font-size: 15px;
    margin-top: -10px;
  }
}
</style>
