<template>
  <HelloWorld msg="Welcome to Your Vue.js App" />
</template>



<script >
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;


   font-family: 'Montserrat'8,sans-serif;
      font-size: 20px;
    font-weight: 400;
    line-height: 1.5;
        color: #212529;
        word-wrap: break-word;

}
</style>
